import React from 'react';
import { StaticQuery, graphql, Link } from 'gatsby';
import Helmet from 'react-helmet';
import Logo from '../components/logo';
import StorageContext from '../context/StorageContext';
import { useMediaQuery } from 'react-responsive'
import { globalHistory } from "@reach/router";
import { editionTypeId } from "../util/constants"

class Header extends React.Component {

  constructor(props) {
    super(props)

    this.state = {
      menuOpen: false
    }
  }

  componentDidMount = () => {
    //this.checkForUpdates()
  };

  //This function grabs the headers for the 404 page
  // I (Peter) removed this code since Gatsby should handle updating the page on it's own now
  /*
  checkForUpdates = () => {
    fetch('/404.html', { method: 'HEAD' })
      .then((result) => {
        // Here the last modified date of the 404 page is compared to the current build date
        // stored globally in the "StorageContext" react context
        if (this.context.currentBuildDate === "") {
          // Here the current build date is set if it has not been initialized yet
          this.context.updateBuild(result.headers.get("last-modified"))
        } else if (this.context.currentBuildDate !== result.headers.get("last-modified")) {
          // Here if the current build date does not match the last modified date of the 404 page
          // That implies that the site has been built since content was last loaded
          window.location.reload()
        }
      }, (error) => {
        console.log("ERROR:", error)
      })
  }*/

  render() {
    return (
      <StaticQuery
        query={graphql`
          query SiteTitleQuery {
            site {
              siteMetadata {
                title
                description
                twitter
                logo
              }
            }
            allFluxibleEditionTypes {
              nodes{
                  alternative_id
                  currentEdition
              }
            }
            allFluxibleEditions{
              edges{
                node{
                  alternative_id
                  editionTypeId
                  fields{
                    slug
                  }
                }
              }
            }
          }
        `}
        render={data => {
          let currentEditionId = data.allFluxibleEditionTypes.nodes.find(elem => elem.alternative_id === parseInt(editionTypeId)).currentEdition
          let currentEdition = data.allFluxibleEditions.edges.find(elem => elem.node.alternative_id === parseInt(currentEditionId)).node
          const editionSlug = currentEdition.fields.slug

          const pathName = globalHistory.location.pathname

          // Setup the appropriate navigation links
          const navLinks = [
            // Tickets link temporarily removed for Fluxible hiatus March 2022
            // { extLink: 'https://fluxible-2021.eventbrite.com/', name: 'Get Tickets', match: '' },
            { slug: `${editionSlug}speakers`, name: 'Speakers', match: `${editionSlug}speakers` },
          ]

          if (currentEdition.editionTypeId === 1) {
            navLinks.push({ slug: `${editionSlug}program-conference`, name: 'Program', match: `${editionSlug}program-` })
          }
          if (currentEdition.editionTypeId === 2) {
            navLinks.push({ slug: `${editionSlug}program-tv`, name: 'Program', match: `${editionSlug}program-` })
          }
          navLinks.push({ slug: `${editionSlug}sponsors`, name: 'Sponsors', match: `${editionSlug}sponsors` })
          if (currentEdition.editionTypeId === 1) {
            navLinks.push({ slug: `${editionSlug}venues`, name: 'Venues', match: `${editionSlug}venues` })
          }
          navLinks.push({ slug: `/news`, name: 'News', match: `/news` })

          const isSmall = useMediaQuery({ query: '(max-width: 65rem)' })

          return (
            <>
              <Helmet
                title={data.site.siteMetadata.title}
                meta={[
                  { name: 'twitter:card', content: 'summary' },
                  {
                    name: 'twitter:site',
                    content: data.site.siteMetadata.twitter,
                  },
                  { property: 'og:title', content: data.site.siteMetadata.title },
                  {
                    property: 'og:description',
                    content: data.site.siteMetadata.description,
                  },
                  {
                    property: 'og:image',
                    content: process.env.GATSBY_WEBSITE_URL + data.site.siteMetadata.logo,
                  },
                  {
                    name: 'twitter:image:alt',
                    content: data.site.siteMetadata.title,
                  },
                ]}
              >
                <html class="no-js" lang="en" />
              </Helmet>

              <header>
                <nav aria-label="main">
                  <div className="header-container">
                    <div className="fluxible-logo">
                      <Logo />
                    </div>

                    {!isSmall &&
                      <div className="menu">
                        {navLinks.map((nav, i) => {
                          if (nav.extLink) {
                            return (
                              <a
                                href={nav.extLink}
                                key={i}
                              >
                                <span>{nav.name}</span>
                              </a>
                            )
                          } else {
                            return (
                              <Link
                                to={nav.slug}
                                key={i}
                                className={pathName.includes(nav.match) ? 'selected-link' : ''}
                                {...pathName.includes(nav.match) ? { "aria-current": 'true' } : ''}
                              >
                                <span>{nav.name}</span>
                              </Link>
                            )
                          }

                        })}
                      </div>
                    }

                    {isSmall &&
                      <button className="menu-icon" aria-hidden="true" onClick={() => this.setState({ menuOpen: !this.state.menuOpen })}></button>
                    }
                  </div>

                  {isSmall &&
                    <div className="mobile-menu" style={{ height: this.state.menuOpen ? 'auto' : '0px' }}>
                      {navLinks.map((nav, i) => {
                        if (nav.extLink) {
                          return (
                            <a
                              href={nav.extLink}
                              key={i}
                            >
                              <span>{nav.name}</span>
                            </a>
                          )
                        } else {
                          return (
                            <Link
                              to={nav.slug}
                              key={i}
                              className={pathName.includes(nav.match) ? 'selected-link' : ''}
                              {...pathName.includes(nav.match) ? { "aria-current": 'true' } : ''}
                            >
                              <span>{nav.name}</span>
                            </Link>
                          )
                        }

                      })}
                    </div>
                  }
                </nav>
              </header>
            </>
          )
        }}
      />
    );
  }
}

Header.contextType = StorageContext

export default Header;