import React from 'react'
import PropTypes from 'prop-types'

import Header from './header'
import Footer from './footer'
import '../styles/app.scss'

const Layout = ({ children }) => (
  <div className="page-viewport">
    <a href="#page-content" className="skip-link">Skip to content</a>
    <Header />
    <main className="page-content" id="page-content">
      {children}
    </main>
    <Footer />
  </div>
)

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
