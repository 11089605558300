import React from 'react'

const FluxibleIconBlock = ({ icon, text, link, size = "sm" }) => {
    const iconString = Array.isArray(icon) ? `${icon[0]} fa-${icon[1]}` : `fa fa-${icon}`;
    return (
        <>
            <span className="icon">
                <i className={`${iconString} fa-${size}`}></i>
            </span>
            {(text && <span className="fluxible_icon_block">
                {link ? (<a href={link}>{text}</a>) : text}
            </span>)}
        </>
    )
}

const FluxibleSocialMediaBlock = ({ icon, link, klass = '', alt = '' }) => {
    const iconString = Array.isArray(icon) ? `${icon[0]} fa-${icon[1]}` : `fa fa-${icon}`;
    return (
        <a href={link} aria-label={alt}>
            <span className={`icon ${klass}`}>
                <i className={`${iconString} fa-2x`}></i>
            </span>
        </a>
    )
}

export { FluxibleIconBlock, FluxibleSocialMediaBlock }
