import React from "react"
import { StaticQuery, graphql, Link } from "gatsby"
import { FluxibleSocialMediaBlock } from '../components/FluxibleIcon'
import { editionTypeId } from '../util/constants.js'

function programSlug(edition) {
  if (edition.editionTypeId === 2) return 'program-tv'
  return 'program-conference'
}

export default () => (
  <StaticQuery
    query={graphql`
        query FooterQuery {
            allFluxibleEditionTypes {
                nodes{
                    alternative_id
                    currentEdition
                }
            }
            allFluxibleEditions(filter: { name: {ne: ""} }, sort: {fields: [startDate], order: DESC}) {
              edges {
                node {
                    id
                    alternative_id
                    name
                    startDate
                    editionTypeId
                    endDate
                    fields {
                        slug
                    }
                }
              }
            }
          }
        `}
    render={data => {
      let currentEditionId = data.allFluxibleEditionTypes.nodes.find(elem => elem.alternative_id === parseInt(editionTypeId)).currentEdition
      let currentEdition = data.allFluxibleEditions.edges.find(elem => elem.node.alternative_id === parseInt(currentEditionId)).node

      //This line filters out the Fluxible 2020 non-TV edition since this edition was cancelled it should not be a past edition
      let editionList = data.allFluxibleEditions.edges.filter(edition => edition.node.alternative_id !== 14)

      return (
        <footer role="contentinfo">
          <div id="fluxible-footer">
            <div>
              <h6>Let’s Connect</h6>
              <p className="footer-social-media">
                <FluxibleSocialMediaBlock
                  icon={['fab', 'facebook-square']}
                  link={'https://www.facebook.com/fluxibleconf/'}
                  klass="first"
                  alt="Fluxible on Facebook"
                />
                <FluxibleSocialMediaBlock
                  icon={['fab', 'twitter-square']}
                  link={'https://twitter.com/Fluxible'}
                  alt="Fluxible on Twitter"
                />
                <FluxibleSocialMediaBlock
                  icon={['fab', 'linkedin']}
                  link={'https://www.linkedin.com/groups/4431261'}
                  alt="Fluxible on Linkedin"
                />
              </p>

              <p>Join our spam-free mailing list and be the first to know about big news or special announcements.</p>
              <p><Link to="/subscribe" className="button subscribe-button">Subscribe for updates</Link></p>
            </div>
            <div>
              <h6>Code of Conduct</h6>
              <p>Fluxible is an inclusive event where everyone is welcomed, open dialogue is encouraged,
              and all perspectives are appreciated. We expect participants to help foster a safe and
                            friendly environment for everyone.</p>

              <p><Link to="/code-of-conduct">Read the full code of conduct</Link></p>
            </div>
            <div>
              <h6>Past Editions</h6>
              <ul>
                {editionList.map(edition => {
                  edition = edition.node;
                  const name = typeof edition.name === "string" ? edition.name.match(/\d{4}/) : ""
                  return new Date(edition.endDate) < new Date() && (
                    <li key={`footer-${edition.id}`} aria-label={name}>
                      <Link to={`${edition.fields.slug}${programSlug(edition)}`} aria-label={`${name} program`}>{name} program</Link>
                      <span aria-hidden="true">&nbsp;|&nbsp;</span>
                      <Link to={`${edition.fields.slug}speakers`} aria-label={`${name} speakers`}>speakers</Link>
                      <span aria-hidden="true">&nbsp;|&nbsp;</span>
                      <Link to={`${edition.fields.slug}sponsors`} aria-label={`${name} sponsors`}>sponsors</Link>
                    </li>)
                })}
              </ul>
            </div>
          </div>
          <div id="fluxible-copyright">
            <div className="copyright-container">
              <div><p><Link to="/contact">Contact us</Link></p></div>
              <div className="text-right">&copy; Copyright Fluxible Inc. All Rights Reserved.</div>
            </div>
          </div>
        </footer>
      )
    }}
  />
)
